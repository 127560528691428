<template>
  <div class="page-wrapper">
    <TheSidebar />
    <div class="right-content-wrapper">
      <v-data-table
        ref="table"
        :headers="headers"
        :items="items"
        :items-per-page.sync="perPage"
        :footer-props="{
          'items-per-page-options': [10, 20, 50, 100, 500],
        }"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDirection"
        :page.sync="page"
        :server-items-length.sync="total"
        @update:options="getItems"
        class="elevation-1"
        style="width: 100%"
        :loading="spinnerActive"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>TeamMembers</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="1000px">
              <template v-slot:activator="{}">
                <v-btn color="primary" dark class="mb-2" v-on:click="addItem">New Item</v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>
                <v-form
                  ref="form"
                  @submit.prevent="save()"
                  v-model="valid"
                  action="/"
                >
                  <v-card-text>
                    <v-container>
                      <!-- <v-row>
                        <v-text-field
                          :rules="[rules.required]"
                          v-model="editedItem.sort_number"
                          label="Sort number"
                        ></v-text-field>
                      </v-row> -->
                      <v-row>
                        <v-text-field
                          v-model="editedItem.license_prefix"
                          label="Prefix"
                        ></v-text-field>
                      </v-row>
                      <v-row>
                        <v-text-field
                          v-model="editedItem.first_name"
                          label="First name"
                        ></v-text-field>
                      </v-row>
                      <v-row>
                        <v-text-field
                          v-model="editedItem.last_name"
                          label="Last name"
                        ></v-text-field>
                      </v-row>
                      <!-- <v-row>
                        <v-text-field
                          v-model="editedItem.type"
                          label="Type"
                        ></v-text-field>
                      </v-row> -->
                      <v-row v-if="editedIndex > -1">
                        <v-text-field
                          v-model="editedItem.sort_number"
                          label="Sorting position"
                        ></v-text-field>
                      </v-row>
                      <v-row>
                        <v-select
                          v-model="editedItem.country"
                          :items="countries"
                          item-text="name"
                          item-value="name"
                          label="Country"
                        ></v-select>
                      </v-row>
                      <v-row>
                        <v-text-field
                          v-model="editedItem.specialist"
                          label="Position"
                        ></v-text-field>
                      </v-row>
                      <v-row>
                        <v-textarea
                          v-model="editedItem.biography"
                          label="Biography"
                        ></v-textarea>
                      </v-row>
                      <v-row>
                        <v-file-input
                          accept="image/* //"
                          :rules="[!!expertImage || 'Required.', rules.size]"
                          placeholder="Pick an image"
                          v-model="editedItem.photo"
                          :input="loadImage()"
                          label="Image"
                          prepend-icon="mdi-paperclip"
                          outlined
                          :show-size="1000"
                        >
                        </v-file-input>
                        <div style="width: 100%">
                          <img
                            v-if="expertImage"
                            :src="getImage(expertImage)"
                            height="100"
                            class="mb-4"
                          />
                        </div>
                      </v-row>
                      <v-row>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-bind="attrs"
                              v-on="on"
                              v-model="editedItem.alt_image"
                              label="SEO - Image alt"
                            ></v-text-field>
                          </template>
                          <span>The Alt tags are used to describe the image to
                            <br>search engines (cannot be seen by the user).
                            <br>Try to create a simple description of the image
                            <br>as if you were describing it to someone with their eyes closed.
                          </span>
                        </v-tooltip>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                    <v-btn color="blue darken-1" text type="submit">Save</v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="1000px">
              <v-card>
                <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <!-- <v-icon small class="mr-2" @click="editItem(item)">mdi-eye</v-icon> -->
          <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small v-if="user && user !== {} && (user.email === 'ronald.westering@cor2ed.com' || user.email === 'mirkovnemanja@gmail.com')" @click="deleteItem(item)">mdi-delete</v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="getItems">No data</v-btn>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import {
  fetchTeamMembers,
  showTeamMember,
  addTeamMember,
  editTeamMember,
  deleteTeamMember,
  fetchCountries
} from "@/services";
import TheSidebar from "@/components/TheSidebar";
import { mapState } from "vuex"

export default {
  name: "TeamMembers",
  components: {
    TheSidebar,
  },
  data() {
    return {
      valid: false,
      perPage: 10,
      sortDirection: false,
      page: 1,
      total: 0,
      sortBy: "sort_number",
      spinnerActive: false,
      dialog: false,
      dialogDelete: false,
      expertImage: false,
      headers: [
        { text: "Order", value: "sort_number" },
        { text: "Prefix", value: "license_prefix" },
        { text: "First name", align: "start", value: "first_name" },
        { text: "Last name", value: "last_name" },
        { text: "Country", value: "country" },
        { text: "Specialization", value: "specialist" },
        { text: "Position", value: "institution" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      items: [],
      countries: [],
      imageUpdated: false,
      fileName: null,
      editedIndex: -1,
      editedItem: {
        first_name: "",
        last_name: "",
        photo: null,
        biography: "",
        type: "",
        license_prefix: "",
        country: "",
      },
      defaultItem: {
        first_name: "",
        last_name: "",
        photo: null,
        biography: "",
        type: "",
        license_prefix: "dr.",
        country: "",
      },
      rules: {
        required: (v) => !!v || "Required.",
        requireLength: (v) => (v != undefined && v.length > 0) || "Required",
        sizes: (files) =>
          !files ||
          !files.some((file) => file.size > 20e6) ||
          "Image size should be less than 1 MB!",
        size: (file) =>
          !file || file.size < 20e6 || "Image size should be less than 20 MB!",
        slug: (v) =>
          /^[a-z0-9-]+$/gm.test(v) ||
          "Must be lowercase and with '-' instead of 'whitespace'.",
      },
    };
  },
  computed: {
    ...mapState(["user"]),
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  mounted() {
    this.getItems();
    this.getCountries();
  },
  methods: {
    async getItems() {
      this.items = [];
      setTimeout(async () => {
        if (this.spinnerActive) return;
        let tableData = {};
        tableData["perPage"] = this.perPage;
        tableData["sortBy"] = this.sortBy;
        tableData["sortDirection"] = this.sortDirection ? "DESC" : "ASC";
        tableData["page"] = this.page;
        this.spinnerActive = true;
        await fetchTeamMembers(tableData)
          .then((res) => {
            if (res.data) {
              this.items = res.data.data.data;
              this.spinnerActive = false;
              this.total = res.data.data.total;
            }
          })
          .catch((error) => {
            console.log(error);
            this.spinnerActive = false;
          });
      }, 0);
    },
    async getCountries() {
      this.items = [];
        await fetchCountries().then((res) => {
          this.countries = JSON.parse(res.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadImage() {
      if (this.editedItem.photo) {
        var file = this.editedItem.photo;
        var reader = new FileReader();
        this.fileName = file.name
        reader.onloadend = async () => {
          if (reader.result.length * (3 / 4) < 20e6) {
            this.expertImage = reader.result;
            this.imageUpdated = true
            this.editedItem.photo = null;
          }
        };
        reader.readAsDataURL(file);
      }
    },
    getImage(imageName) {
      if (imageName && imageName.includes("data:image")) {
        return imageName  
      } else {
        return window.location.origin === 'http://localhost:8080' ? 'http://stage.cor2ed.xyz/api/image/' + imageName : window.location.origin + '/api/image/' + imageName
      }
    },
    addItem() {
      this.editedIndex = -1;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.dialog = true;
    },
    async editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      await showTeamMember(this.editedItem)
        .then((response) => {
          this.expertImage = response.data.data.photo;
          this.editedItem.photo = null
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    deleteItem(item) {
      item.photo = null
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      await deleteTeamMember(this.editedItem)
        .then(() => {
          this.getItems();
          this.closeDelete();
        })
        .then((error) => {
          console.log("deleteTeamMember error => ", error);
        });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.expertImage = null;
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    async save() {
      let formValidated = this.$refs.form.validate();
      if (!formValidated) return;
      let data = Object.assign({}, this.editedItem);
      if (this.imageUpdated) {
        data["photo"] = this.expertImage;
        data["file_name"] = this.fileName;
      }
      
      if (this.editedIndex > -1) {
        // EDIT
        await editTeamMember(data)
          .then(() => {
            this.getItems();
            this.close();
          })
          .then((error) => {
            console.log("addTeamMember error => ", error);
          });
      } else {
        // ADD
        await addTeamMember(data)
          .then(() => {
            this.getItems();
            this.close();
          })
          .then((error) => {
            console.log("addTeamMember error => ", error);
          });
      }
    },
  },
};
</script>
